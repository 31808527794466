angular.module("app").service('_chartService', function ($rootScope, _v, _calendar, $q, _logicService, gettextCatalog) {
    'use strict';

    var EPS = 1e-12,
        self = this;

    var legendGraphOptions = {
        radius: 3,
        sizes: {
            height: 20
        },
        offset: {
            top: 150,
            right: 40,
            bottom: 2
        },
        data: [{
            color: '#ede1c1',
            textColor: "black",
            width: 140
        }, {
            text: gettextCatalog.getString('Threshold'),
            color: '#ede1c1',
            textColor: "black",
            width: 70
        }, {
            text: gettextCatalog.getString('High'),
            color: '#ff6600',
            textColor: "white",
            width: 70

        }, {
            text: gettextCatalog.getString('Average'),
            color: '#ff9900',
            textColor: "white",
            width: 70
        }, {
            text: gettextCatalog.getString('Low'),
            color: '#fff',
            textColor: "black",
            width: 70
        }]
    };

    function showCircle(p) {
        d3.select('.' + p.circle).style('opacity', '1');
        d3.select('.' + p.text).style('opacity', '1');
    }

    function hideCircle(p) {
        d3.select('.' + p.circle).style('opacity', '0');
        d3.select('.' + p.text).style('opacity', '0');
    }

    function drawCircles(svg, c) {
        let group = svg.append('g').attr('class', c.type + '-graph');

        _.each(c.points, (p, i) => {

            var point = {
                circle: c.type + '-graph-circle-' + i,
                text: c.type + '-graph-text-' + i
            };

            group.append('circle')
                .attr('cx', p[0])
                .attr('cy', p[1])
                .attr('r', 5)
                .attr('fill', c.style.fill)
                .attr('stroke-width', '2px')
                .attr('stroke', c.style.stroke)
                .attr('class', point.circle)
                .style('opacity', '0')
                .on('mouseover', () => {
                    showCircle(point);
                })
                .on('mouseout', () => {
                    hideCircle(point);
                });

            group.append('text')
                .attr('x', p[0])
                .attr('y', p[1] - 20)
                .attr('fill', c.style.fill)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'central')
                .attr('dominant-baseline', 'central')
                .attr('class', point.text)
                .style('opacity', '0')
                .text(_logicService.round(c.data[i][c.type]));
        });
    }

    function getMaxMinAvg(d, thresholdsUnit) {
        var result = {};
        if (thresholdsUnit == "NUMBER_INSECTS") {
            result.min = d.minInsects;
            result.max = d.maxInsects;
            result.avg = d.avgInsects;
        }
        if (thresholdsUnit == "NUMBER_PLANTS") {
            result.min = d.minPlants;
            result.max = d.maxPlants;
            result.avg = d.avgPlants;
        }
        if (thresholdsUnit == "PERCENT_PLANTS") {
            result.min = d.minPlantsPercent;
            result.max = d.maxPlantsPercent;
            result.avg = d.avgPlantsPercent;
        }
        return result;
    }

    function getUnitValue(value, thresholdsUnit) {
        if (thresholdsUnit == "NUMBER_INSECTS") {
            return value.cellCount[0];
        }
        if (thresholdsUnit == "NUMBER_PLANTS") {
            return value.cellCount[1];
        }
        if (thresholdsUnit == "PERCENT_PLANTS") {
            return value.cellCount[2];
        }
    }

    this.pointInsideMinMax = function (graphHeight, min = 0, max = 0, val = 0, BORDER = 1) {
        var k = 0.5; //default is middle
        if (!_.isUndefined(min) && !_.isUndefined(max) && Math.abs(max-min) > EPS) {
            k = (max - val) / (max - min);
        }

        return _logicService.round(BORDER + (graphHeight - 2*BORDER) * k);
    };

    this.clearSprayGraph = function() {
        if (_.isUndefined(document.getElementById('insectsGraph')) || _.isNull(document.getElementById('insectsGraph'))) {
            return;
        }
        d3.select("#insectsGraph").selectAll("*").remove();
    };

    this.buildSprayGraphLegend = function (svg, graphSettings, thresholdsUnit, pointLegend) {
        _.each(legendGraphOptions.data, (legend, i) => {
            var pointLegendDY = 50;
            let group = svg.append('g'),
                offsetX = graphSettings.graphWidthPx - (legend.width + legendGraphOptions.offset.right),
                offsetY = (graphSettings.topMarginPx + legendGraphOptions.offset.top) + (legendGraphOptions.sizes.height + legendGraphOptions.offset.bottom) * i;

            // Offset between thresholds and other legends
            if (i >= 2) offsetY += 5;

            if (i === 0 || i === 1 || i === 3) {
                var rectangle = group.append('rect');

                rectangle.attr('x', offsetX).attr('y', offsetY)
                    .attr('height', legendGraphOptions.sizes.height)
                    .attr('width', legend.width)
                    .attr('fill', legend.color);

                // Make the corners of the first rectangle rounded.
                (i === 0 || i === 1) ? rectangle.attr('rx', legendGraphOptions.radius)
                                     .attr('ry', legendGraphOptions.radius): null;

                if (i == 3 && pointLegend != null) {
                    var rectangle = group.append('rect');
                    rectangle.attr('x', offsetX).attr('y', offsetY + pointLegendDY)
                        .attr('height', legendGraphOptions.sizes.height)
                        .attr('rx', legendGraphOptions.radius)
                        .attr('ry', legendGraphOptions.radius)
                        .attr('width', legend.width)
                        .attr('fill', '#7fffd4');
                }

            } else { // The second and the third rectangles
                var coordinates = [offsetX, offsetY];

                (i === 2) ? makeTopCornersRounded(group, coordinates, legend): makeBottomCornersRounded(group, coordinates, legend);
            }

            var text = legend.text;
            if (i==0) {
                if (thresholdsUnit == "NUMBER_INSECTS") {
                    text = gettextCatalog.getString("# of insects per area");
                }
                if (thresholdsUnit == "NUMBER_PLANTS") {
                    text = gettextCatalog.getString("# of damaged plants");
                }
                if (thresholdsUnit == "PERCENT_PLANTS") {
                    text = gettextCatalog.getString("% of damaged plants");
                }
            }
            group.append('text').attr('x', offsetX + legend.width / 2)
                .attr('y', offsetY + legendGraphOptions.sizes.height / 2)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'central')
                .attr('dominant-baseline', 'central')
                .attr('font-size', 11)
                .attr('fill', legend.textColor)
                .text(text);

            if (i==3 && pointLegend != null) {
                group.append('text').attr('x', offsetX + legend.width / 2)
                    .attr('y', offsetY + legendGraphOptions.sizes.height / 2 + pointLegendDY)
                    .attr('text-anchor', 'middle')
                    .attr('alignment-baseline', 'central')
                    .attr('dominant-baseline', 'central')
                    .attr('font-size', 11)
                    .attr('fill', 'white')
                    .text(pointLegend);
            }
        });

        function makeTopCornersRounded(group, coordinates, legend) {
            group.append("path").attr("d", topRoundedRect(coordinates[0],
                coordinates[1],
                legend.width,
                legendGraphOptions.sizes.height,
                legendGraphOptions.radius)
            ).attr('fill', legend.color);
        }

        function makeBottomCornersRounded(group, coordinates, legend) {
            group.append('path').attr('d', bottomRoundedRect(coordinates[0],
                coordinates[1],
                legend.width,
                legendGraphOptions.sizes.height,
                legendGraphOptions.radius)
            ).attr('fill', legend.color);
        }

        // Returns path data for a rectangle with rounded top corners.
        // The top-left corner is ⟨x,y⟩.
        function topRoundedRect(x, y, width, height, radius) {
            return "M" + x + "," + (y + radius)
                + "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius
                + "h" + (width - 2 * radius)
                + "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius
                + "v" + (height - radius)
                + "h" + (-width)
                + "z";
        }

        // Returns path data for a rectangle with rounded bottom corners.
        // The top-left corner is ⟨x,y⟩.
        function bottomRoundedRect(x, y, width, height, radius) {
            return "M" + x + "," + y
                + "h" + (width)
                + "v" + (height - radius)
                + "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + radius
                + "h" + (2 * radius - width)
                + "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + -radius
                + "z";
        }
    };

    var ui = {
        zoom: {
            radius: 3,
            height: 20,
            width: 20,
            style: {
                color: '#979797',
                background: '#fff',
                shadow: '0 0 3px rgba(0, 0, 0, .5)',
                cursor: 'pointer',
                selection: 'none',
                alignment: 'central',
                fontSize: '11px'
            },
            data: [{
                symbol: '+',
                id: 'graph-zoom-in'
            }, {
                symbol: '-',
                id: 'graph-zoom-out'
            }],
            offset: {
                top: legendGraphOptions.offset.top / 10,
                bottom: legendGraphOptions.offset.bottom,
                right: legendGraphOptions.offset.right
            }
        }
    };

    this.buildSprayGraphModel = function (scaleY, thresholds, thresholdsUnit, insectsData, insect, pointData, pointName) {
        if (_.isUndefined(document.getElementById('insectsGraph')) || _.isNull(document.getElementById('insectsGraph'))) {
            return;
        }

        var self = this;
        self.clearSprayGraph();

        var maxThreshold = insect.maxThreshold * scaleY;
        if (thresholdsUnit == "NUMBER_PLANTS") {
            maxThreshold = insect.maxThresholdPlants * scaleY;
        }
        if (thresholdsUnit == "PERCENT_PLANTS") {
            maxThreshold = 100 * scaleY;
        }

        var graphHeightPx = 540,
            topMarginPx = 80,
            bottomMarginPx = 80,
            browserWidthPx = document.body.clientWidth,
            result = {thresholdPoints: [], insectPointsMax: [], insectPointsMin: [], insectPointsAvg: [], lastThresholdPoint: null,
            graphHeightPx: graphHeightPx, topMarginPx: topMarginPx, bottomMarginPx: bottomMarginPx, graphWidthPx: browserWidthPx,
            maxThreshold: maxThreshold};

        var deferred = $q.defer(),
            anotherDeferred = $q.defer(),
            thresholdsPromise = $q.defer();

        if (insect) {
            _calendar.getDisplayedDateBoundariesPromise().then(function(calendarBoundaries) {
                if (calendarBoundaries == null) {
                    return;
                }
                var from = calendarBoundaries.from;
                var to = calendarBoundaries.to;

                if (!_.isUndefined(thresholds[insect.id])) {
                    var promisesArray = [];

                    thresholds[insect.id].thresholds.forEach(point => {
                        var s = moment.utc(point.startDate);
                        var e = moment.utc(point.endDate);
                        if (s.isBefore(from) && e.isAfter(from)) {
                            s = from;
                        }
                        if (s.isBefore(to) && e.isAfter(to)) {
                            e = to;
                        }
                        promisesArray.push(_calendar.getPositionForDatePromise(s));
                        promisesArray.push(_calendar.getPositionForDatePromise(e));
                    });
                    $q.all(promisesArray).then(function(positions) {
                        _.each(positions, function(pos, i) {
                            if (pos != null) {
                                var point = thresholds[insect.id].thresholds[Math.floor(i / 2)];
                                var p = {
                                    x: _logicService.round((browserWidthPx / 100) * pos),
                                    y: topMarginPx + self.pointInsideMinMax(graphHeightPx-topMarginPx-bottomMarginPx, 0, result.maxThreshold, point.value)
                                };
                                result.thresholdPoints.push([p.x, p.y]);
                                result.lastThresholdPoint = p;
                            }
                        });
                        thresholdsPromise.resolve();
                    });
                } else {
                    thresholdsPromise.resolve();
                }

                var minMaxAvgPromises = [];
                var promisesArrayArray = [];
                var minMaxArrayArray = [];

                var currentChart = [];
                var currentPromises = [];
                var lastDate = null;
                _.each(insectsData[insect.id], function(value, key) {

                    var day = moment.utc(key);
                    if (day.isSameOrAfter(from) && day.isBefore(to)) {
                        value.date = day;

                        if (lastDate != null && day.diff(lastDate, 'd') != 1) { //new chart fragment
                            promisesArrayArray.push(currentPromises);
                            minMaxArrayArray.push(currentChart);
                            minMaxAvgPromises.push($q.defer());
                            currentPromises = [];
                            currentChart = [];
                        }

                        currentPromises.push(_calendar.getPositionForDatePromise(day));
                        currentChart.push(value);
                        lastDate = day.clone();
                    }
                });
                if (_.size(currentPromises) > 0) {
                    promisesArrayArray.push(currentPromises);
                    minMaxArrayArray.push(currentChart);
                    minMaxAvgPromises.push($q.defer());
                }

                //add new point at the end of last day
                _.each(minMaxArrayArray, function(minMaxArray, i) {
                    var size = _.size(minMaxArray);
                    if (size > 0) {
                        minMaxArray.push(minMaxArray[size-1]);
                        promisesArrayArray[i].push(_calendar.getPositionForDatePromise(minMaxArray[size-1].date.clone().add(1, 'd').subtract(1, 'ms')));
                    }
                });

                result.insectPointsArray = [];
                _.each(promisesArrayArray, function(promises, i) {
                    $q.all(promises).then(function(positions) {
                        var ddd = {insectPointsMax: [], insectPointsMin: [], insectPointsAvg: [], insectData: []};
                        _.each(positions, function(pos, ii) {
                            var x = _logicService.round((browserWidthPx / 100) * pos);
                            var maxMinAvgBean = getMaxMinAvg(minMaxArrayArray[i][ii], thresholdsUnit);
                            ddd.insectPointsMax.push([x, topMarginPx + self.pointInsideMinMax(graphHeightPx-topMarginPx-bottomMarginPx, 0, result.maxThreshold, maxMinAvgBean.max)]);
                            ddd.insectPointsMin.push([x, topMarginPx + self.pointInsideMinMax(graphHeightPx-topMarginPx-bottomMarginPx, 0, result.maxThreshold, maxMinAvgBean.min)]);
                            ddd.insectPointsAvg.push([x, topMarginPx + self.pointInsideMinMax(graphHeightPx-topMarginPx-bottomMarginPx, 0, result.maxThreshold, maxMinAvgBean.avg)]);
                            ddd.insectData.push({
                                max: maxMinAvgBean.max,
                                min: maxMinAvgBean.min,
                                avg: maxMinAvgBean.avg
                            });
                        });
                        result.insectPointsArray.push(ddd);
                        minMaxAvgPromises[i].resolve();
                    });
                });

                //cell insect graph
                var pointPromise = $q.defer();
                if (pointName && pointData && !_.isUndefined(pointData[insect.id])) {
                    result.pointData = {data: [], pointName: ""+pointName};
                    var promisesArray2 = [];
                    var minMaxArray2 = [];

                    _.each(pointData[insect.id], function(value, key) {
                        var day = moment.utc(key);
                        if (day.isSameOrAfter(from) && day.isSameOrBefore(to)) {
                            promisesArray2.push(_calendar.getPositionForDatePromise(day));
                            minMaxArray2.push({cellCount: getUnitValue(value, thresholdsUnit)});
                        } else if (day.diff(to) == 1) { //day just after last column
                            promisesArray2.push(_calendar.getPositionForDatePromise(to));
                            minMaxArray2.push({cellCount: getUnitValue(value, thresholdsUnit)});
                        }
                    });

                    $q.all(promisesArray2).then(function(positions) {
                        _.each(positions, function(pos, i) {
                            var x = _logicService.round((browserWidthPx / 100) * pos);
                            result.pointData.data.push([x, topMarginPx + self.pointInsideMinMax(graphHeightPx-topMarginPx-bottomMarginPx, 0, result.maxThreshold, minMaxArray2[i].cellCount)]);
                        });
                        pointPromise.resolve();
                    });
                } else {
                    pointPromise.resolve();
                }

                var xx = _.map(minMaxAvgPromises, function(e) {return e.promise});
                xx.push(thresholdsPromise.promise);
                xx.push(pointPromise.promise);

                $q.all(xx).then(function() {
                    deferred.resolve(result);
                });
            });
        } else {
            deferred.resolve(null);
        }

        deferred.promise.then(function (data) {
            if (data != null) {

                d3.select("#insectsGraph").selectAll("*").remove();
                var svg = d3.select("#insectsGraph").append("svg")
                                                    .attr("width", data.graphWidthPx)
                                                    .attr("height", data.graphHeightPx)
                                                    .attr("user-select", "none");

                svg.append('line').attr("x1", 0).attr("y1", data.topMarginPx)
                                                .attr("x2", data.graphWidthPx)
                                                .attr("y2", data.topMarginPx)
                                                .attr('stroke-dasharray', '5,5')
                                                .attr('stroke-width', 2)
                                                .attr('stroke', 'white');

                svg.append('line').attr("x1", 0).attr("y1", data.graphHeightPx-data.bottomMarginPx-1)
                                                .attr("x2", data.graphWidthPx)
                                                .attr("y2", data.graphHeightPx-data.bottomMarginPx-1)
                                                .attr('stroke-dasharray', '5,5')
                                                .attr('stroke-width', 2)
                                                .attr('stroke', 'white');


                svg.append("path").datum(data.thresholdPoints)
                    .attr('stroke-width', '3px').attr('stroke', '#ede1c1').attr('fill', 'none')
                    .attr("d", d3.line());

                _.each(data.insectPointsArray, function(d) {
                    svg.append("path").datum(d.insectPointsMin)
                        .attr('stroke-width', '4px').attr('stroke', '#ffffff').attr('fill', 'none')
                        .attr("d", d3.line().curve(d3.curveMonotoneX));

                    svg.append("path").datum(d.insectPointsAvg)
                        .attr('stroke-width', '4px').attr('stroke', '#ff9900').attr('fill', 'none')
                        .attr("d", d3.line().curve(d3.curveMonotoneX));

                    svg.append("path").datum(d.insectPointsMax)
                        .attr('stroke-width', '4px').attr('stroke', '#ff6600').attr('fill', 'none')
                        .attr("d", d3.line().curve(d3.curveMonotoneX));

                    let circle = {
                        svg: svg,
                        points: d.insectPointsMin,
                        data: d.insectData,
                        type: 'min',
                        style: {
                            fill: '#ffffff',
                            stroke: '#ffffff'
                        }
                    };


                    // Draw min data points
                    drawCircles(svg, circle);

                    circle.points = d.insectPointsAvg;
                    circle.type = 'avg';
                    circle.style.stroke = '#ff9900';

                    // Draw avg data points
                    drawCircles(svg, circle);

                    circle.points = d.insectPointsMax;
                    circle.type = 'max';
                    circle.style.stroke = '#ff6600';

                    // Draw max data points
                    drawCircles(svg, circle);
                });

                svg.append('text').attr("text-anchor", "end").attr("y", data.topMarginPx - 7).attr("x", "100%").attr("transform", "translate(-5)")
                    .attr("fill", "white").style("font-size", "14px").text(data.maxThreshold);

                var pointLegend = null;
                if (data.pointData) {
                    svg.append("path").datum(data.pointData.data)
                        .attr('stroke-width', '4px').attr('stroke', '#7fffd4').attr('fill', 'none')
                        .attr("d", d3.line().curve(d3.curveMonotoneX));

                    svg.append('circle').attr("cx", data.pointData.data[_.size(data.pointData.data)-1][0])
                        .attr("cy", data.pointData.data[_.size(data.pointData.data)-1][1]).attr("r", 14)
                        .attr("fill", "#7fffd4").attr("transform", "translate(-26, -1)");

                    svg.append('text').attr("text-anchor", "middle").attr("x", data.pointData.data[_.size(data.pointData.data)-1][0])
                        .attr("y", data.pointData.data[_.size(data.pointData.data)-1][1])
                        .attr("transform", "translate(-26, 4)").attr("fill", "white").text(data.pointData.pointName);
                }
                if (pointName) {
                    pointLegend = gettextCatalog.getString('Point') + " " + pointName;
                }

                self.buildSprayGraphLegend(svg, data, thresholdsUnit, pointLegend);
            } else {
                d3.select("#insectsGraph").selectAll("*").remove();
            }
        });
    };
});